
import * as aroraProviderRuntime$JnDJp5ROihNDCPNcAbcqi5gZKIsI4ADcNXv7lLOtIg8 from 'D:/deploy/UploadHidden/20250409-P903.338/arora-front/satellite/build-rest-8cdff211-5a54-4ade-bf31-9e5ea62eb088/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$JnDJp5ROihNDCPNcAbcqi5gZKIsI4ADcNXv7lLOtIg8, defaults: {} }
}
        